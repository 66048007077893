import gql from "graphql-tag";

export const recipeFragment = gql`
  fragment RecipeFields on Recipe {
    __typename
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    slug
    title
    shortTitle
    category {
      sys {
        id
        publishedAt
        firstPublishedAt
      }
      name
      slug
    }
    time
    yield
    editorNotes
    primaryImages: primaryImagesCollection(limit: 6) {
      total
      skip
      limit
      items {
        title
        url
        width
        height
      }
    }
    tags: tagsCollection(limit: 5) {
      total
      skip
      limit
      items {
        sys {
          id
          publishedAt
          firstPublishedAt
        }
        name
        slug
      }
    }
    richDescription {
      json
    }
  }
`;

export const recipeStubFragment = gql`
  fragment RecipeStubFields on Recipe {
    __typename
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    slug
    title
    shortTitle
    category {
      sys {
        id
        publishedAt
        firstPublishedAt
      }
      name
      slug
    }
    primaryImages: primaryImagesCollection(limit: 6) {
      total
      skip
      limit
      items {
        title
        url
        width
        height
      }
    }
  }
`;

export const recipeFragmentExtended = gql`
  fragment RecipeFieldsExtended on Recipe {
    ingredients
    directions
    primaryImages: primaryImagesCollection(limit: 6) {
      total
      skip
      limit
      items {
        title
        url
        width
        height
      }
    }
    otherImages: otherImagesCollection(limit: 6) {
      total
      skip
      limit
      items {
        title
        url
        width
        height
      }
    }
  }
`;

export const categoryFragment = gql`
  fragment RecipeCategoryFields on Category {
    __typename
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    name
    slug
    order
    showInNavigationBar
    featuredRecipes: featuredRecipesCollection {
      total
      skip
      limit
      items {
        ...RecipeFields
      }
    }
  }
  ${recipeFragment}
`;

export const tagFragment = gql`
  fragment TagFields on Tag {
    __typename
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    name
    slug

    description
    featuredRecipes: featuredRecipesCollection {
      total
      skip
      limit
      items {
        ...RecipeFields
      }
    }
  }
  ${recipeFragment}
`;

export const salesLandingPageFragment = gql`
  fragment SalesLandingPageFields on SalesLandingPage {
    __typename
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    title
    slug
    markdown
  }
`;
export const salesLandingPageFragmentExtended = gql`
  fragment SalesLandingPageFieldsExtended on SalesLandingPage {
    markdown
  }
`;

export const regulatoryPageFragment = gql`
  fragment RegulatoryPageFields on RegulatoryPage {
    __typename
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    title
    slug
    content
    lastUpdated
  }
`;
